<template>
  <div>
    <b-container>
      <b-row>
        <b-col md="4" offset-md="2" class="text-left text-white leftContenet">
          <b-col md="12" class="text-right">
            <div class="topCircle">
              <p class="circleMainText">5.500</p>
              <p class="circleSubText">
                consumenten <br />
                gingen u voor
              </p>
            </div>
          </b-col>
          <b-container>
            <img class="googleReview" src="../assets/GoogleReview.png" />
            <h3 class="contentLeft-upperText mt-2">
              Bespaar <br />
              energiekosten <br />
              met zonnepanelen.
            </h3>
            <img src="../assets/divider.png" width="100px" />
          </b-container>
        </b-col>
        <b-col md="4" class="text-white">
          <div id="formSection" class="formSection p-4 d-flex flex-column">
            <div class="my-auto text-center">
              <img
                v-if="profId !== null"
                referrerpolicy="no-referrer-when-downgrade"
                :src="`https://republish.verbeterthuis.be/m/4563/785ee31554f5/?event=5553&unique_conversion_id=${profId}`"
                style="width: 1px; height: 1px; border: 0px"
              />
              <!-- <h1>{{profId}}</h1> -->
              <h5>
                Bedankt voor uw aanvraag. Wij nemen zo spoedig mogelijk contact
                met u op!
              </h5>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AfterSubmit",
  computed: mapGetters(["profId"]),
};
</script>

<style>
.topCircle {
  height: 130px;
  width: 130px;
  background-color: #f08105;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  line-height: 25px;
  user-select: none;
  margin-left: auto;
}

.circleMainText {
  font-size: 25pt;
  margin: 0px;
}
.circleSubText {
  font-size: 10pt;
  margin: 0px;
  line-height: 15px;
}
.leftContenet {
  position: relative;
  top: -65px;
}
.contentLeft-upperText {
  font-weight: normal;
  color: white;
}
.formSection {
  height: auto;
  width: 100%;
  background-color: #04853b;
  border: 2px solid white;
  border-radius: 27px;
  position: relative;
  top: -70px;
}
.inputField {
  border: none;
  background-color: #04853b;
  color: white;
  border-bottom: white 1px solid;
  width: 100%;
  margin: 11px 0px;
}
.inputField::placeholder {
  color: rgba(255, 255, 255, 0.808);
}
.inputField:focus {
  outline: none;
  border-bottom: 2px solid white;
  width: 100%;
}
.btn-submit {
  width: 100%;
  background-color: #f08105 !important;
  border: 0 !important;
  color: rgb(237, 237, 237) !important;
}
.formText {
  font-size: 7.8px;
  text-align: justify;
}

@media (max-width: 1224px) {
  .topCircle {
    height: 110px;
    width: 110px;
  }
  .formSection {
    height: 515px;
  }
}
@media (max-width: 915px) {
  .topCircle {
    height: 100px;
    width: 100px;
  }
  .contentLeft-upperText {
    font-size: large;
    line-height: 25px;
  }
  .contentLeft-midText {
    margin-top: 5px;
    font-size: 15px;
  }
  .contentLeft-lowerText {
    font-size: 7px;
    line-height: 20px;
  }
  .formTitle {
    font-size: medium;
  }
  .formSection {
    font-size: small;
  }
}
@media (max-width: 820px) {
  .topCircle {
    height: 105px;
    width: 105px;
  }
}

@media (max-width: 767px) {
  .formSection {
    height: 430px;
    top: -35px;
  }
}
@media (max-width: 600px) {
  .topCircle {
    height: 110px;
    width: 110px;
  }
  .googleReview {
    left: 190px;
    top: 135px;
    width: 150px;
  }
  .contentLeft-upperText {
    font-size: xx-large;
    line-height: 42px;
  }
  .contentLeft-midText {
    margin-top: 10px;
    font-size: 23px;
  }
  .contentLeft-lowerText {
    font-size: 13px;
    line-height: 20px;
  }
}
</style>
